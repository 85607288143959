import { Link, StaticQuery, graphql } from 'gatsby'
import React from "react"

import { pathTo } from '../../routes'
import Container from '../container'

import logo from '../../assets/images/logo_quadrat.jpg'
import * as styles from './index.module.scss'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.row}>
          <div className={styles.news}>
            <div className={styles.headline}>Über uns</div>
            <div className={styles.text}>Wir stehen für eine sorgenfreie digitale Zukunft.<br />&mdash;<br />Dank unserer digitalen Infrastruktur können wir effizente Prozesse abbilden. Der regelmäßige persönliche Kontakt mit unseren Mandanten hat trotzdem oberste Priorität.</div>
            <div className={styles.logo}>
              <img
                src={logo}
                alt='Advice - Steuerberatung in Dresden'
                title="Advice - Steuerberatung in Dresden"
              />
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.contact}>
              <div className={styles.headline}>Advice Rost & Kollegen Steuerberatungsgesellschaft mbH</div>
              <div className={styles.text}>
                Reichsstr. 22<br />
                09112 Chemnitz<br />
                <br />
                Großenhainer Str. 32<br />
                01097 Dresden Neustadt
                <br />
              </div>
              <div className={styles.headline}>Telefon</div>
              <div className={styles.text}>
                Chemnitz: +49 371 / 38 155 0<br />
                Dresden: +49 351 / 811 97 500
              </div>
              <div className={styles.headline}>E-Mail</div>
              <div className={styles.text}>info@advice-steuerberatung.de</div>
            </div>
            <div className={styles.navigation}>
              <StaticQuery
                query={graphql`
                  query MetaNavigationQuery {
                    meta: contentfulNavigationZone(title: {eq: "Meta"}) {
                      items {
                        __typename
                        ... on ContentfulPage {
                          id
                          slug
                          title
                        }
                      }
                    }
                  }
                `}
                render={data => {
                  if (data === undefined || data.meta === undefined) return null

                  return [...data.meta.items].map(item => (
                    <Link
                      key={item.id}
                      to={pathTo(item)}
                      className={styles.link}
                    >{item.title}</Link>
                  ))
                }}
              />
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
